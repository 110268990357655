"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketOverviewHelpCenter = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const ComponentConfigContext_1 = require("../../../context/ComponentConfigContext");
const andes_icons_1 = require("@yonderland/andes-icons");
const ImageUtil_1 = require("../../../util/ImageUtil");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const HELP_CENTER_IMAGE_WIDTH = 48;
const BasketOverviewHelpCenter = () => {
    var _a;
    const { helpCenter } = (0, ComponentConfigContext_1.useBasketOverviewConfig)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const imageCdnPrefix = (0, ConfigurationUtil_1.getImageCdnPredix)(aemConfiguration);
    if (!helpCenter.title) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { backgroundColor: "quiet", padding: "4", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { itemsAlign: "center", gap: "4", children: [helpCenter.image && ((0, jsx_runtime_1.jsx)(andes_react_1.Image, { width: HELP_CENTER_IMAGE_WIDTH, alt: (_a = helpCenter.image.alt) !== null && _a !== void 0 ? _a : 'help-center-image', source: helpCenter.image.imagePath, sourceSet: (0, ImageUtil_1.getImageSrcset)(helpCenter.image, imageCdnPrefix) })), (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { itemsAlign: "center", gap: "2", children: [(0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { itemsAlign: "center", children: [Boolean(helpCenter.title) && ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { alignment: "center", color: "neutral", variant: "titleM", children: helpCenter.title })), Boolean(helpCenter.phoneTitle) && ((0, jsx_runtime_1.jsx)(andes_react_1.ConditionalWrapper, { condition: !!helpCenter.phoneLink, wrapper: children => (0, jsx_runtime_1.jsx)(andes_react_1.Link, { href: `tel:${helpCenter.phoneLink}`, children: children }), children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { alignment: "center", color: "subdued", children: helpCenter.phoneTitle }) })), Boolean(helpCenter.workingHours) && ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { alignment: "center", color: "subdued", children: helpCenter.workingHours }))] }), Boolean(helpCenter.targetUrl) && Boolean(helpCenter.targetText) && ((0, jsx_runtime_1.jsx)(andes_react_1.Link, { href: helpCenter.targetUrl, variant: "primary", theme: "branded", iconRight: { size: 'sm', source: andes_icons_1.ArrowRightIcon }, children: helpCenter.targetText }))] })] }) }));
};
exports.BasketOverviewHelpCenter = BasketOverviewHelpCenter;
