"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeDeliveryDayPreview = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const CheckoutDeliveryContext_1 = require("../../../../context/CheckoutDeliveryContext");
const common_queries_1 = require("@as-react/common-queries");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const react_query_1 = require("@tanstack/react-query");
const BuyFormUtil_1 = require("../../../../util/BuyFormUtil");
const react_1 = require("react");
const BasketUtil_1 = require("../../../../util/BasketUtil");
const axios_1 = require("axios");
const DeliveryPreselectedCardView_1 = require("../DeliveryPreselectedCardView");
const DateUtil_1 = require("../../../../util/DateUtil");
const HomeDeliveryDayPreview = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const root = (0, ConfigurationUtil_1.getRoot)(aemConfiguration);
    const [{ info, activeDeliveryOption }, dispatch] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const { getNamedDayDeliveryDates } = (0, common_queries_1.checkoutQueries)({ servicesEndpoint, defaultRequestParams });
    const { data: deliveryDates, error: deliveryDatesError } = (0, react_query_1.useQuery)(getNamedDayDeliveryDates({
        enabled: (0, BuyFormUtil_1.showNamedDays)(activeDeliveryOption),
    }));
    (0, react_1.useEffect)(() => {
        if ((0, axios_1.isAxiosError)(deliveryDatesError)) {
            if (BasketUtil_1.default.isBasketNotFound(deliveryDatesError) || BasketUtil_1.default.isBasketNotInitialized(deliveryDatesError)) {
                location.href = `${root}checkout/basket-overview.html`;
            }
        }
    }, [deliveryDatesError]);
    if (!deliveryDates) {
        return null;
    }
    const deliveryDate = deliveryDates.find(date => { var _a; return date.date.toString() === ((_a = info.deliveryDate) === null || _a === void 0 ? void 0 : _a.toString()); });
    const getDeliveryDateLabel = (date) => DateUtil_1.default.isTomorrow(date.date) ? `${t('checkout.delivery.delivery.day.tomorrow')} ${date.label}` : date.label;
    const handleEditDeliveryDay = () => {
        dispatch({ type: 'set_open_selector', payload: 'delivery-day' });
    };
    const getHeaderContent = () => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "bodyS", children: t('checkout.delivery.delivery.day.title') }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { strong: true, children: getDeliveryDateLabel(deliveryDate ? deliveryDate : deliveryDates[0]) })] }));
    return ((0, jsx_runtime_1.jsx)(DeliveryPreselectedCardView_1.DeliveryPreselectedCardView, { dataTestId: "hd_date_card", header: getHeaderContent(), onEdit: handleEditDeliveryDay }));
};
exports.HomeDeliveryDayPreview = HomeDeliveryDayPreview;
