"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketOverviewItems = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const common_types_1 = require("@as-react/common-types");
const react_redux_1 = require("react-redux");
const BasketSelector_1 = require("../../../redux/basket/selectors/BasketSelector");
const DeliveryPromiseUtil_1 = require("../../../util/DeliveryPromiseUtil");
const react_1 = require("react");
const BasketOverviewItem_1 = require("./BasketOverviewItem");
const common_queries_1 = require("@as-react/common-queries");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const react_query_1 = require("@tanstack/react-query");
const StoreUtil_1 = require("../../../util/StoreUtil");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const AnalyticsActions_creators_1 = require("../../../redux/analytics/actions/AnalyticsActions.creators");
const getProductDisabledReason = (product, deliveryMessages) => {
    if ((0, DeliveryPromiseUtil_1.isDeliveryMessageCollectStoreOrHQ)(deliveryMessages)) {
        if (product.ccExcluded) {
            return common_types_1.CheckoutBlockedReason.CC_EXCLUDED;
        }
        else if (product.sseProduct && !product.availableInSSEStore) {
            return common_types_1.CheckoutBlockedReason.SSE_PRODUCT;
        }
        else if (product.specialtyStoreProduct && !product.availableInSpecialtyStore) {
            return common_types_1.CheckoutBlockedReason.SPECIALTY_PRODUCT;
        }
    }
    else {
        if (product.sseProduct) {
            return common_types_1.CheckoutBlockedReason.SSE_PRODUCT;
        }
    }
};
const BasketOverviewItems = ({ products }) => {
    var _a;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const businessUnitId = (0, ConfigurationUtil_1.getBusinessUnitId)(aemConfiguration);
    const mainWebShop = (0, ConfigurationUtil_1.getMainWebShop)(aemConfiguration);
    const dispatch = (0, react_redux_1.useDispatch)();
    const deliveryInfo = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketDeliveryInfoState)());
    const clickAndCollectStoreId = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketClickAndCollectStoreIdState)());
    const { getStoreInformation } = (0, common_queries_1.storeQueries)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const isCheckoutFlowDisabled = products.some(product => (0, DeliveryPromiseUtil_1.isDeliveryMessageCollectStoreOrHQ)(deliveryInfo.deliveryMessages)
        ? product.ccExcluded ||
            (product.sseProduct && !product.availableInSSEStore) ||
            (product.specialtyStoreProduct && !product.availableInSpecialtyStore)
        : product.sseProduct);
    const { data: storeInformation } = (0, react_query_1.useQuery)(getStoreInformation({
        businessUnitId,
        storeId: clickAndCollectStoreId,
    }, {
        enabled: !!clickAndCollectStoreId &&
            (!!((_a = deliveryInfo.deliveryMessages) === null || _a === void 0 ? void 0 : _a[common_types_1.DeliveryOptionsEnum.COLLECT_STORE]) || isCheckoutFlowDisabled),
    }));
    const storeName = storeInformation ? StoreUtil_1.default.getStoreName(storeInformation, mainWebShop, t) : undefined;
    const disabledProductsList = (0, react_1.useMemo)(() => {
        return products.reduce((acc, product) => {
            const reason = getProductDisabledReason(product, deliveryInfo.deliveryMessages);
            if (!reason) {
                return acc;
            }
            return Object.assign(Object.assign({}, acc), { [product.productId]: reason });
        }, {});
    }, [products, deliveryInfo]);
    (0, react_1.useEffect)(() => {
        if (isCheckoutFlowDisabled) {
            dispatch(AnalyticsActions_creators_1.analyticsActions.blockedCheckout(disabledProductsList));
        }
    }, [disabledProductsList]);
    return ((0, jsx_runtime_1.jsx)(andes_react_1.StackBlock, { gap: "8", children: products.map((product, index, arr) => ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(BasketOverviewItem_1.BasketOverviewItem, { product: product, isDisabled: product.productId in disabledProductsList, storeName: storeName }), arr.length - 1 !== index && (0, jsx_runtime_1.jsx)(andes_react_1.Divider, {})] }, product.sku))) }));
};
exports.BasketOverviewItems = BasketOverviewItems;
