"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductTileBasketOverview = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const ImageUtil_1 = require("../../../util/ImageUtil");
const andes_react_1 = require("@yonderland/andes-react");
const common_utils_1 = require("@as-react/common-utils");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const react_i18next_1 = require("react-i18next");
const asNumeral_1 = require("../../../vendors/asNumeral");
const andes_icons_1 = require("@yonderland/andes-icons");
const DeliveryPromiseMessageV2_1 = require("../../product-detail/delivery-promise-v2/DeliveryPromiseMessageV2");
const DeliveryPromiseUtil_1 = require("../../../util/DeliveryPromiseUtil");
const ProductUtil_1 = require("../../../util/ProductUtil");
const WishlistButtonAndes_1 = require("../../wishlist-button/WishlistButtonAndes");
const usePriceViewModel_1 = require("../../../hooks/usePriceViewModel");
const ComponentConfigContext_1 = require("../../../context/ComponentConfigContext");
const react_redux_1 = require("react-redux");
const BasketActions_creators_1 = require("../../../redux/basket/actions/BasketActions.creators");
const BasketSelector_1 = require("../../../redux/basket/selectors/BasketSelector");
const getSizeOptions = (product) => {
    var _a;
    if ((product.productType === common_types_1.ProductTypes.PRODUCT || product.productType === common_types_1.ProductTypes.GIFT_CARD) &&
        !!((_a = product.sizes) === null || _a === void 0 ? void 0 : _a.length)) {
        return product.sizes.sort((sizeA, sizeB) => sizeA.order - sizeB.order);
    }
    return [];
};
const ProductTileBasketOverview = ({ product }) => {
    var _a, _b, _c, _d, _e;
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.navigation, i18nConstants_1.I18N_NAMESPACES.product, i18nConstants_1.I18N_NAMESPACES.checkout]);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const productImageConfiguration = (0, ConfigurationUtil_1.getProductImages)(aemConfiguration);
    const currency = (0, ConfigurationUtil_1.getCurrency)(aemConfiguration);
    const oneSizeProductCode = (0, ConfigurationUtil_1.getOneSizeProductCode)(aemConfiguration);
    const root = (0, ConfigurationUtil_1.getRoot)(aemConfiguration);
    const dispatch = (0, react_redux_1.useDispatch)();
    const isBasketLocked = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketLockState)());
    const sizeOptions = getSizeOptions(product);
    const oneSizeProduct = (0, common_utils_1.isOneSizeProduct)(product.sizes, oneSizeProductCode);
    const { filteredSizes, options } = (0, ProductUtil_1.getSizeSelectOptions)(sizeOptions, true, product.sku);
    const hasSizes = !oneSizeProduct && (options === null || options === void 0 ? void 0 : options.length) > 0;
    const { general: { showMultilineDeliveryPromise }, } = (0, ComponentConfigContext_1.useBasketOverviewConfig)();
    const image = (0, ImageUtil_1.getImageUrlForBasketTile)(product, productImageConfiguration, common_types_1.ProductImageSizesPortraitEnum.basket);
    const getRequestObject = (quantity) => ({
        quantity,
        sku: String(product.sku),
        productId: String(product.productId),
        colorId: product.colorId,
        productCode: product.productCode,
        productPriceManual: product.priceManual,
        additionalProperties: product.additionalProperties,
    });
    const priceActual = {
        formattedPrice: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(product.totalPrice),
    };
    const pricePrevious = product.productHasDiscount && !!product.totalPreviousPrice
        ? {
            formattedPrice: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(product.totalPreviousPrice),
            priceLabel: ((_a = product.prices) === null || _a === void 0 ? void 0 : _a.RRP) ? t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.price.rrp`) : undefined,
        }
        : undefined;
    const priceViewModel = (0, usePriceViewModel_1.usePriceViewModel)({
        prices: product.prices,
        hasDiscount: Boolean(product.productHasDiscount),
    }, {
        priceActual,
        pricePrevious,
    });
    const discountAmount = asNumeral_1.AsNumeral.getInstance().formatAsCurrency(product.totalProductDiscountAmount);
    const deliveryPromiseExplanation = product.productType !== common_types_1.ProductTypes.E_VOUCHER
        ? (0, DeliveryPromiseUtil_1.getDeliveryExplanation)({ stockLocation: (_b = product.additionalProperties) === null || _b === void 0 ? void 0 : _b.stockLocation })
        : undefined;
    const getDeliveryPromise = () => {
        if (product.productType === common_types_1.ProductTypes.E_VOUCHER) {
            return (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.mailbox`) });
        }
        if (!product.additionalProperties)
            return null;
        const { additionalProperties } = product;
        return additionalProperties.deliveryPromiseCutoff ? ((0, jsx_runtime_1.jsx)(DeliveryPromiseMessageV2_1.default, { hdDeliveryPromise: {
                nextDayDeliverable: true,
                messages: {
                    [additionalProperties.deliveryOptionName]: additionalProperties.deliveryMessage,
                },
                cutoff: additionalProperties.deliveryPromiseCutoff,
            }, unavailable: true, deliveryPromiseExplanation: deliveryPromiseExplanation, icon: { name: 'circle-fill', color: 'success' } })) : additionalProperties.deliveryMessage ? ((0, jsx_runtime_1.jsx)(andes_react_1.StatusIndicator, { status: additionalProperties.stockLocation === common_types_1.StockLocation.IN_STORE ? 'complete' : 'partiallyComplete', children: additionalProperties.deliveryMessage })) : null;
    };
    const deliveryPromise = showMultilineDeliveryPromise ? getDeliveryPromise() : null;
    const productDetailUrl = (0, ProductUtil_1.getProductDetailUrl)({
        seoUrl: product.seoUrl,
        colourId: product.colorId,
    }, root);
    const handleSizeChange = (size) => {
        const sku = String(size.id);
        if (String(product.sku) !== sku) {
            dispatch(BasketActions_creators_1.basketActions.createChangeSizeAction(getRequestObject(product.quantity), sku));
        }
    };
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.ProductTileHorizontal, { dataTestId: "basket_product", product: Object.assign(Object.assign({ alt: [product.brand, product.description, product.colorDescription].join(' '), image,
            currency }, priceViewModel), { title: product.description, brand: product.brand }), children: [(0, jsx_runtime_1.jsx)(andes_react_1.ProductTile.Cell, { section: "media", children: (0, jsx_runtime_1.jsx)(andes_react_1.ProductTileImage, { height: {
                        lg: 180,
                        xs: 120,
                    }, width: {
                        lg: 120,
                        xs: 80,
                    }, url: productDetailUrl }) }), (0, jsx_runtime_1.jsx)(andes_react_1.ProductTile.Cell, { section: "content", children: (0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { gap: "4", columns: 1, fullHeight: true, children: [(0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "4", wrap: "nowrap", itemsAlign: "start", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { flex: {
                                        grow: 1,
                                    }, children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "2", children: [(0, jsx_runtime_1.jsx)(andes_react_1.ProductTileTitle, { brandOnTheSameLine: true, url: productDetailUrl }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subdued-2", children: t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:basket.flyout.options.color`, {
                                                    color: product.color,
                                                }) })] }) }), (0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "1", wrap: "nowrap", children: [(0, jsx_runtime_1.jsx)(WishlistButtonAndes_1.WishlistButtonAndes, { productCode: product.productCode, productId: Number(product.productId), colourId: product.colorId, skuId: (_c = product.sku) === null || _c === void 0 ? void 0 : _c.toString(), dataTestId: "basket_product_add_to_wishlist" }), (0, jsx_runtime_1.jsx)(andes_react_1.IconButton, { icon: {
                                                source: andes_icons_1.XIcon,
                                            }, size: "sm", theme: "neutral", variant: "plain", dataTestId: "basket_product_remove" })] })] }), (0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { columns: 6, gap: "4", children: [hasSizes && ((0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { offset: 1, span: {
                                        xs: 6,
                                        md: 3,
                                        xl: 2,
                                    }, children: (0, jsx_runtime_1.jsx)(andes_react_1.SelectAlpha, { onSelect: handleSizeChange, input: {
                                            id: 'size',
                                            disabled: isBasketLocked,
                                            required: true,
                                        }, items: filteredSizes.map(size => ({ id: size.sku, text: (0, ProductUtil_1.selectOptionLabel)(size, true) })), value: (_d = product.sku) === null || _d === void 0 ? void 0 : _d.toString(), dataTestId: "basket_product_size_selector" }) })), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { rowOffset: { md: 2 }, offset: 1, span: {
                                        xs: 6,
                                        md: 3,
                                        xl: 2,
                                    }, align: "end", children: (0, jsx_runtime_1.jsx)(andes_react_1.QuantitySelector, { dataTestId: "basket_product_amount", onUpdate: () => undefined, quantity: product.quantity }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { align: "end", justify: "end", offset: {
                                        md: 4,
                                    }, rowOffset: {
                                        md: 1,
                                    }, rowSpan: {
                                        md: 2,
                                    }, span: {
                                        xs: 6,
                                        md: 3,
                                        xl: 4,
                                    }, children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "1", itemsAlign: "end", children: [deliveryPromise, (0, jsx_runtime_1.jsx)(andes_react_1.ProductTile.Price, {}), product.productHasDiscount && ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "bodyXS", color: "commercial", dataTestId: "product_discount_percentage", children: `${t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.discount.label`)} ${currency}${discountAmount} / ${(_e = product.productDiscountPercentage) === null || _e === void 0 ? void 0 : _e.toString()}%` }))] }) })] })] }) })] }));
};
exports.ProductTileBasketOverview = ProductTileBasketOverview;
